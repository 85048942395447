import img_0 from "../../assets/images/lottie/img_0.png";
import img_1 from "../../assets/images/lottie/img_1.png";
import img_2 from "../../assets/images/lottie/img_2.png";
import img_3 from "../../assets/images/lottie/img_3.png";
import img_4 from "../../assets/images/lottie/img_4.png";
import img_5 from "../../assets/images/lottie/img_5.png";
import img_6 from "../../assets/images/lottie/img_6.png";
import img_7 from "../../assets/images/lottie/img_7.png";
import img_8 from "../../assets/images/lottie/img_8.png";
import img_9 from "../../assets/images/lottie/img_9.png";

export const data = {
	"v": "5.6.3",
	"fr": 24,
	"ip": 0,
	"op": 720,
	"w": 1080,
	"h": 1920,
	"nm": "mobile",
	"ddd": 0,
	"assets": [
		{"id": "image_0", "w": 1092, "h": 288, "u": "", "p": img_0, "e": 0},
		{"id": "image_1", "w": 1354, "h": 1355, "u": "", "p": img_1, "e": 0},
		{"id": "image_2", "w": 1354, "h": 1355, "u": "", "p": img_2, "e": 0},
		{"id": "image_3", "w": 1354, "h": 1355, "u": "", "p": img_3, "e": 0},
		{"id": "image_4", "w": 1354, "h": 1355, "u": "", "p": img_4, "e": 0},
		{"id": "image_5", "w": 1354, "h": 1355, "u": "", "p": img_5, "e": 0},
		{"id": "image_6", "w": 1354, "h": 1355, "u": "", "p": img_6, "e": 0},
		{"id": "image_7", "w": 1354, "h": 1355, "u": "", "p": img_7, "e": 0},
		{"id": "image_8", "w": 1354, "h": 1355, "u": "", "p": img_8, "e": 0},
		{"id": "image_9", "w": 1354, "h": 1355, "u": "", "p": img_9, "e": 0},
		{"id": "comp_0", "layers": [{
			"ddd": 0,
			"ind": 1,
			"ty": 5,
			"nm": "oh 3",
			"parent": 2,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-82, -199, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 2,
			"ty": 4,
			"nm": "Linalool 3",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [1215, 241, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-197, 204], [-197, 120]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[-120, 70], [-120, -49]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-289.88, -223.373], [-196.626, -125.783], [-101.205, -58.554], [-101.205, 69.398]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-88.199, 268.247], [-179.777, 216.536]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-270.361, 266.747], [-179.277, 216.867], [-179.277, 106.265], [-272.53, 56.386], [-272.53, -52.048], [-127.229, -195.181]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 3,
			"ty": 5,
			"nm": "oh",
			"parent": 4,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-82, -199, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 4,
			"ty": 4,
			"nm": "Linalool",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [520, 661, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-197, 204], [-197, 120]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[-120, 70], [-120, -49]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-289.88, -223.373], [-196.626, -125.783], [-101.205, -58.554], [-101.205, 69.398]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-88.199, 268.247], [-179.777, 216.536]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-270.361, 266.747], [-179.277, 216.867], [-179.277, 106.265], [-272.53, 56.386], [-272.53, -52.048], [-127.229, -195.181]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 5,
			"ty": 5,
			"nm": "oh 2",
			"parent": 6,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-82, -199, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 6,
			"ty": 4,
			"nm": "Linalool 2",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [987, 1660, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-197, 204], [-197, 120]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[-120, 70], [-120, -49]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-289.88, -223.373], [-196.626, -125.783], [-101.205, -58.554], [-101.205, 69.398]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-88.199, 268.247], [-179.777, 216.536]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-270.361, 266.747], [-179.277, 216.867], [-179.277, 106.265], [-272.53, 56.386], [-272.53, -52.048], [-127.229, -195.181]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 7,
			"ty": 5,
			"nm": "2",
			"parent": 9,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [341, 106, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "2",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 8,
			"ty": 5,
			"nm": "ch  oh 2",
			"parent": 9,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [343, 90, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH  OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 9,
			"ty": 4,
			"nm": "Citronellool",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [644, 928, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[151, 225], [151, 137]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0]],
							"v": [[262, 44], [262, -62], [149, -108]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[261, 287], [169, 234]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[148, -240], [148, -108], [71, -41], [71, 74], [168, 128], [168, 234], [74, 288]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 10,
			"ty": 5,
			"nm": "5",
			"parent": 12,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [343, 109, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "2",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 11,
			"ty": 5,
			"nm": "ch  oh 3",
			"parent": 12,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [343, 90, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH  OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 12,
			"ty": 4,
			"nm": "Citronellool 2",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [188, 1372, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[151, 225], [151, 137]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0]],
							"v": [[262, 44], [262, -62], [149, -108]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[261, 287], [169, 234]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[148, -240], [148, -108], [71, -41], [71, 74], [168, 128], [168, 234], [74, 288]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 13,
			"ty": 5,
			"nm": "8",
			"parent": 14,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-2, 16, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "2",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 14,
			"ty": 5,
			"nm": "ch  oh 5",
			"parent": 15,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-403, -115, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH  OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 15,
			"ty": 4,
			"nm": "Gernaiol 3",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [672, 327, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-647, -102], [-570, -79]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-666, -202], [-661, -89]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-661, 242], [-661, 156]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-552, 306], [-644, 252]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-507, -116], [-562, -61], [-662, -90], [-737, -14], [-737, 93], [-644, 147], [-644, 253], [-737, 306]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 16,
			"ty": 5,
			"nm": "3",
			"parent": 17,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-2, 16, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "2",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 17,
			"ty": 5,
			"nm": "ch  oh",
			"parent": 18,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-403, -115, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH  OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 18,
			"ty": 4,
			"nm": "Gernaiol",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [667, 1167, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-647, -102], [-570, -79]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-666, -202], [-661, -89]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-661, 242], [-661, 156]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-552, 306], [-644, 252]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-507, -116], [-562, -61], [-662, -90], [-737, -14], [-737, 93], [-644, 147], [-644, 253], [-737, 306]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 19,
			"ty": 5,
			"nm": "6",
			"parent": 20,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-2, 16, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "2",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 20,
			"ty": 5,
			"nm": "ch  oh 4",
			"parent": 21,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-403, -115, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH  OH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 21,
			"ty": 4,
			"nm": "Gernaiol 2",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [1372, 75, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-647, -102], [-570, -79]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-666, -202], [-661, -89]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-661, 242], [-661, 156]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[-552, 306], [-644, 252]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-507, -116], [-562, -61], [-662, -90], [-737, -14], [-737, 93], [-644, 147], [-644, 253], [-737, 306]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 5,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 22,
			"ty": 5,
			"nm": "4",
			"parent": 23,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [57.6, 7.4, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "3",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 23,
			"ty": 5,
			"nm": "ch",
			"parent": 26,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [295, 226, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 24,
			"ty": 5,
			"nm": "S",
			"parent": 26,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [157, 289, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "S",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 25,
			"ty": 5,
			"nm": "o",
			"parent": 26,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [65, 146, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "O",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 26,
			"ty": 4,
			"nm": "Methyl",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [1001, 1107, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[72.5, 158.5], [72.5, 226]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[57, 157.5], [57, 229.5]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[246, 212], [178, 256]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-414, 276], [-302, 208], [-194, 276], [-104, 224], [-18, 276], [66, 224], [136, 258]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 27,
			"ty": 5,
			"nm": "7",
			"parent": 28,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [57.6, 7.4, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 40,
							"f": "ProximaNova-Light",
							"t": "3",
							"j": 2,
							"tr": 0,
							"lh": 48,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 28,
			"ty": 5,
			"nm": "ch 2",
			"parent": 31,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [295, 226, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "CH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 29,
			"ty": 5,
			"nm": "S 2",
			"parent": 31,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [157, 289, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "S",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 30,
			"ty": 5,
			"nm": "o 3",
			"parent": 31,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [65, 146, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "O",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 31,
			"ty": 4,
			"nm": "Methyl 2",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-195, 612, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[72.5, 158.5], [72.5, 226]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0]],
							"o": [[0, 0], [0, 0]],
							"v": [[57, 157.5], [57, 229.5]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[246, 212], [178, 256]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[-414, 276], [-302, 208], [-194, 276], [-104, 224], [-18, 276], [66, 224], [136, 258]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 32,
			"ty": 5,
			"nm": "sh",
			"parent": 34,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [462, 62, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "SH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 33,
			"ty": 5,
			"nm": "o 2",
			"parent": 34,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [606, -134, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "O",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 34,
			"ty": 4,
			"nm": "M4",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [935, 1167, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[616, -123], [616, -53]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[600, -122], [600, -51]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[440, -157], [440, 13]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[351, -4], [439, -56], [524, -4], [608, -55], [720, 7]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 35,
			"ty": 5,
			"nm": "sh 2",
			"parent": 37,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [462, 62, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "SH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 36,
			"ty": 5,
			"nm": "o 4",
			"parent": 37,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [606, -134, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "O",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 37,
			"ty": 4,
			"nm": "M5",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [191, 604, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[616, -123], [616, -53]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[600, -122], [600, -51]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[440, -157], [440, 13]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[351, -4], [439, -56], [524, -4], [608, -55], [720, 7]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 38,
			"ty": 5,
			"nm": "sh 3",
			"parent": 40,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [462, 62, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "SH",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 39,
			"ty": 5,
			"nm": "o 5",
			"parent": 40,
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [606, -134, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"t": {
				"d": {
					"k": [{
						"s": {
							"s": 60,
							"f": "ProximaNova-Light",
							"t": "O",
							"j": 2,
							"tr": 0,
							"lh": 72,
							"ls": 0,
							"fc": [0.478, 0.765, 0.341]
						}, "t": 0
					}]
				}, "p": {}, "m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}}, "a": []
			},
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}, {
			"ddd": 0,
			"ind": 40,
			"ty": 4,
			"nm": "M6",
			"sr": 1,
			"ks": {
				"o": {"a": 0, "k": 100, "ix": 11},
				"r": {"a": 0, "k": 0, "ix": 10},
				"p": {"a": 0, "k": [-199, 1970, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
			},
			"ao": 0,
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[616, -123], [616, -53]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 4",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[600, -122], [600, -51]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 2,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[440, -157], [440, 13]], "c": false},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 3,
				"mn": "ADBE Vector Group",
				"hd": false
			}, {
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[351, -4], [439, -56], [524, -4], [608, -55], [720, 7]],
							"c": false
						},
						"ix": 2
					},
					"nm": "Path 1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ty": "st",
					"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
					"o": {"a": 0, "k": 100, "ix": 4},
					"w": {"a": 0, "k": 5, "ix": 5},
					"lc": 1,
					"lj": 1,
					"ml": 4,
					"bm": 0,
					"nm": "Stroke 1",
					"mn": "ADBE Vector Graphic - Stroke",
					"hd": false
				}, {
					"ty": "tr",
					"p": {"a": 0, "k": [0, 0], "ix": 2},
					"a": {"a": 0, "k": [0, 0], "ix": 1},
					"s": {"a": 0, "k": [100, 100], "ix": 3},
					"r": {"a": 0, "k": 0, "ix": 6},
					"o": {"a": 0, "k": 100, "ix": 7},
					"sk": {"a": 0, "k": 0, "ix": 4},
					"sa": {"a": 0, "k": 0, "ix": 5},
					"nm": "Transform"
				}],
				"nm": "Shape 1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 4,
				"mn": "ADBE Vector Group",
				"hd": false
			}],
			"ip": 0,
			"op": 720,
			"st": 0,
			"bm": 0
		}]}
	],
	"fonts": {
		"list": [{
			"fName": "ProximaNova-Light",
			"fFamily": "Proxima Nova",
			"fStyle": "Light",
			"ascent": 66.69921875
		}]
	},
	"layers": [{
		"ddd": 0,
		"ind": 1,
		"ty": 2,
		"nm": "Logo.png",
		"cl": "png",
		"refId": "image_0",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [540, 960, 0], "ix": 2},
			"a": {"a": 0, "k": [546, 144, 0], "ix": 1},
			"s": {"a": 0, "k": [50, 50, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 25,
			"nm": "Drop Shadow",
			"np": 8,
			"mn": "ADBE Drop Shadow",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Shadow Color",
				"mn": "ADBE Drop Shadow-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 1], "ix": 1}
			}, {
				"ty": 0,
				"nm": "Opacity",
				"mn": "ADBE Drop Shadow-0002",
				"ix": 2,
				"v": {"a": 0, "k": 163.2, "ix": 2}
			}, {
				"ty": 0,
				"nm": "Direction",
				"mn": "ADBE Drop Shadow-0003",
				"ix": 3,
				"v": {"a": 0, "k": 180, "ix": 3}
			}, {
				"ty": 0,
				"nm": "Distance",
				"mn": "ADBE Drop Shadow-0004",
				"ix": 4,
				"v": {"a": 0, "k": 13, "ix": 4}
			}, {
				"ty": 0,
				"nm": "Softness",
				"mn": "ADBE Drop Shadow-0005",
				"ix": 5,
				"v": {"a": 0, "k": 50, "ix": 5}
			}, {"ty": 7, "nm": "Shadow Only", "mn": "ADBE Drop Shadow-0006", "ix": 6, "v": {"a": 0, "k": 0, "ix": 6}}]
		}],
		"ip": 0,
		"op": 720,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 2,
		"ty": 3,
		"nm": "Null 4",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 0, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [530, 957, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"ip": 0,
		"op": 720,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 3,
		"ty": 5,
		"nm": "grapefruit",
		"parent": 4,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [342.294, -346.534, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "GRAPEFRUIT",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 119,
							"s": [0]
						}, {"t": 160, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 4,
		"ty": 4,
		"nm": "Shape Layer 13",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [508, 1159.5, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [200, 200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 109, "s": [0]}, {
					"t": 150,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 5,
		"ty": 5,
		"nm": "TROPICAL",
		"parent": 6,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": -180, "ix": 10},
			"p": {"a": 0, "k": [508.206, -369.966, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "TROPICAL",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 109,
							"s": [0]
						}, {"t": 150, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 6,
		"ty": 4,
		"nm": "Shape Layer 12",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [819, 457.5, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-200, -200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 99, "s": [0]}, {
					"t": 140,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 7,
		"ty": 5,
		"nm": "Lot ID 18amt90225 analysis overview: bright, crisp with  floral characteristics",
		"parent": 8,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [351.5, -408, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16,
						"f": "ProximaNova-Light",
						"t": "LOT ID 18AMT90225\rANALYSIS OVERVIEW:\rBRIGHT, CRISP WITH \rFLORAL CHARACTERISTICS",
						"j": 0,
						"tr": 100,
						"lh": 19.2000007629395,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 67,
							"s": [0]
						}, {"t": 112, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 8,
		"ty": 4,
		"nm": "Shape Layer 11",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [619.582, 1407.3, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [200, 200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr", "it": [{
				"ind": 0, "ty": "sh", "ix": 1, "ks": {
					"a": 1,
					"k": [{
						"t": 41,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[292, -292.5], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 47,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[98.5, -143.5], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 61,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[140, -183.5], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 109,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[269, -277.5], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 152,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[100, -144.5], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 180,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[310.536, -310.929], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 203,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[80.536, -116.929], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 241,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[152.202, -196.929], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}],
					"ix": 2
				}, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 53, "s": [0]}, {
					"t": 94,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 9,
		"ty": 4,
		"nm": "Square 8",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 0, "s": [0], "h": 1}, {"t": 24, "s": [100], "h": 1}], "ix": 11},
			"r": {
				"a": 1,
				"k": [{"t": 0, "s": [0], "h": 1}, {"t": 36, "s": [45], "h": 1}, {"t": 109, "s": [0], "h": 1}, {
					"t": 180,
					"s": [45],
					"h": 1
				}, {"t": 192, "s": [0], "h": 1}, {"t": 241, "s": [45], "h": 1}],
				"ix": 10
			},
			"p": {"a": 0, "k": [677, 807.5, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 0, "s": [144, 144, 100], "h": 1}, {"t": 61, "s": [250, 250, 100], "h": 1}, {
					"t": 97,
					"s": [150, 150, 100],
					"h": 1
				}, {"t": 152, "s": [300, 300, 100], "h": 1}, {"t": 192, "s": [300, 300, 100], "h": 1}, {
					"t": 222,
					"s": [200, 200, 100],
					"h": 1
				}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 10,
		"ty": 4,
		"nm": "Circle 27",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 0, "s": [0], "h": 1}, {"t": 11, "s": [100], "h": 1}], "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 807.5, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 0, "s": [164, 164, 100], "h": 1}, {"t": 47, "s": [200, 200, 100], "h": 1}, {
					"t": 61,
					"s": [100, 100, 100],
					"h": 1
				}, {"t": 109, "s": [150, 150, 100], "h": 1}, {"t": 152, "s": [400, 400, 100], "h": 1}, {
					"t": 180,
					"s": [175, 175, 100],
					"h": 1
				}, {"t": 203, "s": [350, 350, 100], "h": 1}, {"t": 241, "s": [100, 100, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 11,
		"ty": 2,
		"nm": "Lines 6",
		"parent": 12,
		"refId": "image_1",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.333, "y": 0},
					"t": 4,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1176.797, 7.5], [234.5, 7.5], [234.5, -8.5], [1176.797, -8.5]],
						"c": true
					}]
				}, {
					"t": 53,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1176.797, -22.5], [315, -22.5], [315, 1733.5], [1176.797, 1733.5]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 12,
		"ty": 2,
		"nm": "Hop 1",
		"parent": 2,
		"refId": "image_2",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {
				"a": 1,
				"k": [{"t": 0, "s": [7, -37, 0], "h": 1}, {"t": 180, "s": [7, -58, 0], "h": 1}, {
					"t": 222,
					"s": [526, 364, 0],
					"h": 1
				}, {"t": 241, "s": [526, 869, 0], "h": 1}, {"t": 270, "s": [1229, -37, 0], "h": 1}],
				"ix": 2
			},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 0, "s": [40, 40, 100], "h": 1}, {"t": 24, "s": [60, 60, 100], "h": 1}, {
					"t": 53,
					"s": [50, 50, 100],
					"h": 1
				}, {"t": 163, "s": [30, 30, 100], "h": 1}, {"t": 180, "s": [70, 70, 100], "h": 1}, {
					"t": 222,
					"s": [100, 100, 100],
					"h": 1
				}, {"t": 241, "s": [25, 25, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.333, "y": 0},
					"t": 8,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1229, 93.5], [85, 93.5], [85, 1629.5], [1229, 1629.5]],
						"c": true
					}]
				}, {
					"t": 57,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[187, 93.5], [85, 93.5], [85, 1629.5], [187, 1629.5]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 13,
		"ty": 2,
		"nm": "Hop 6",
		"parent": 12,
		"refId": "image_3",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 14,
		"ty": 5,
		"nm": "FLORAL",
		"parent": 15,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [343.094, -346.434, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "FLORAL",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 368,
							"s": [0]
						}, {"t": 404, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 15,
		"ty": 4,
		"nm": "Shape Layer 16",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [488, 951.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [200, 200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 358, "s": [0]}, {
					"t": 394,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 16,
		"ty": 5,
		"nm": "LEMON",
		"parent": 17,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": -180, "ix": 10},
			"p": {"a": 0, "k": [474.406, -364.066, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "LEMON",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 379,
							"s": [0]
						}, {"t": 415, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 17,
		"ty": 4,
		"nm": "Shape Layer 15",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [787, 215.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-200, -200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 369, "s": [0]}, {
					"t": 405,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 18,
		"ty": 5,
		"nm": "CITRUS",
		"parent": 19,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": -180, "ix": 10},
			"p": {"a": 0, "k": [474.406, -364.066, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "CITRUS",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 391,
							"s": [0]
						}, {"t": 427, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 19,
		"ty": 4,
		"nm": "Shape Layer 20",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [995, 616.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-200, -200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 381, "s": [0]}, {
					"t": 417,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 20,
		"ty": 5,
		"nm": "Lot ID 37CMT92445 analysis overview: Noble, floral characteristics with citrus undertones",
		"parent": 21,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [351.5, -360, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, -100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16,
						"f": "ProximaNova-Light",
						"t": "LOT ID 37CMT92445\rANALYSIS OVERVIEW:\rNOBLE, FLORAL CHARACTERISTICS\rWITH CITRUS UNDERTONES",
						"j": 0,
						"tr": 100,
						"lh": 19.2000007629395,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 321,
							"s": [0]
						}, {"t": 361, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 21,
		"ty": 4,
		"nm": "Lot ID Lines",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [654, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [200, -200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 1,
					"k": [{
						"t": 353,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[112.75, -117], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 420,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[187.75, -189], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 442,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[112.75, -115.143], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 456,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[113.083, -115.667], [336, -334], [592, -334], [592, -432], [336.25, -432], [336.25, -334.5]],
							"c": false
						}],
						"h": 1
					}],
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 312, "s": [0]}, {
					"t": 348,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 22,
		"ty": 4,
		"nm": "Square 7",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 281, "s": [100], "h": 1}, {"t": 509, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 355, "s": [45], "h": 1}, {
					"t": 408,
					"s": [0],
					"h": 1
				}, {"t": 445, "s": [0], "h": 1}, {"t": 456, "s": [0], "h": 1}],
				"ix": 10
			},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [144, 144, 100], "h": 1}, {"t": 281, "s": [250, 250, 100], "h": 1}, {
					"t": 432,
					"s": [400, 400, 100],
					"h": 1
				}, {"t": 456, "s": [200, 200, 100], "h": 1}, {"t": 480, "s": [0, 0, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 23,
		"ty": 4,
		"nm": "Square 6",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 264, "s": [100], "h": 1}, {"t": 509, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 276, "s": [45], "h": 1}, {
					"t": 294,
					"s": [0],
					"h": 1
				}, {"t": 312, "s": [45], "h": 1}, {"t": 392, "s": [0], "h": 1}, {
					"t": 445,
					"s": [45],
					"h": 1
				}, {"t": 480, "s": [0], "h": 1}],
				"ix": 10
			},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [144, 144, 100], "h": 1}, {"t": 392, "s": [200, 200, 100], "h": 1}, {
					"t": 432,
					"s": [300, 300, 100],
					"h": 1
				}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 24,
		"ty": 4,
		"nm": "Circle 26",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 288, "s": [100], "h": 1}, {"t": 432, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [0, 0, 100], "h": 1}, {"t": 288, "s": [300, 300, 100], "h": 1}, {
					"t": 301,
					"s": [250, 250, 100],
					"h": 1
				}, {"t": 325, "s": [300, 300, 100], "h": 1}, {"t": 336, "s": [50, 50, 100], "h": 1}, {
					"t": 355,
					"s": [100, 100, 100],
					"h": 1
				}, {"t": 390, "s": [500, 500, 100], "h": 1}, {"t": 420, "s": [100, 100, 100], "h": 1}, {
					"t": 432,
					"s": [0, 0, 100],
					"h": 1
				}],
				"ix": 6
			}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 25,
		"ty": 4,
		"nm": "Circle 25",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 251, "s": [100], "h": 1}, {"t": 528, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [164, 164, 100], "h": 1}, {"t": 300, "s": [100, 100, 100], "h": 1}, {
					"t": 355,
					"s": [164, 164, 100],
					"h": 1
				}, {"t": 442, "s": [100, 100, 100], "h": 1}, {"t": 480, "s": [450, 450, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 26,
		"ty": 2,
		"nm": "Lines 5",
		"parent": 27,
		"refId": "image_4",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.167, "y": 0},
					"t": 248,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1332, -1652.519], [162.651, -1652.519], [162.651, 103.481], [1332, 103.481]],
						"c": true
					}]
				}, {
					"t": 308,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1132, -23.25], [163, -23.25], [163, 1732.75], [1132, 1732.75]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 27,
		"ty": 2,
		"nm": "Hop 2",
		"parent": 2,
		"refId": "image_5",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {
				"a": 1,
				"k": [{"t": 216, "s": [-1561, 28, 0], "h": 1}, {"t": 240, "s": [-572, -25, 0], "h": 1}, {
					"t": 277,
					"s": [17, -55.375, 0],
					"h": 1
				}, {"t": 408, "s": [17, -22.375, 0], "h": 1}, {"t": 456, "s": [-350, -456.375, 0], "h": 1}, {
					"t": 480,
					"s": [-493, 1190.625, 0],
					"h": 1
				}, {"t": 528, "s": [26, -1865.375, 0], "h": 1}],
				"ix": 2
			},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 216, "s": [100, 100, 100], "h": 1}, {"t": 240, "s": [100, 100, 100], "h": 1}, {
					"t": 277,
					"s": [60, 60, 100],
					"h": 1
				}, {"t": 312, "s": [40, 40, 100], "h": 1}, {"t": 408, "s": [35, 35, 100], "h": 1}, {
					"t": 456,
					"s": [100, 100, 100],
					"h": 1
				}, {"t": 480, "s": [75, 75, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.333, "y": 0},
					"t": 252,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1070.902, 93.5], [85, 93.5], [85, 1629.5], [1070.902, 1629.5]],
						"c": true
					}]
				}, {
					"t": 312,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1285, 92.75], [1009.5, 92.75], [1009.5, 1628.75], [1285, 1628.75]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 28,
		"ty": 2,
		"nm": "Hop 5",
		"parent": 27,
		"refId": "image_6",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 29,
		"ty": 5,
		"nm": "sweet aromatic",
		"parent": 30,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": -180, "ix": 10},
			"p": {"a": 0, "k": [342.406, -339.066, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-100, -100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 32,
						"f": "ProximaNova-Light",
						"t": "SWEET AROMATIC",
						"j": 0,
						"tr": 100,
						"lh": 38.4,
						"ls": 0,
						"fc": [1, 1, 1]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 590,
							"s": [0]
						}, {"t": 638, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 30,
		"ty": 4,
		"nm": "Shape Layer 18",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [401, 1159.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [200, 200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0]],
						"v": [[158, -156], [336, -334], [336.25, -334.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 580, "s": [0]}, {
					"t": 628,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 31,
		"ty": 5,
		"nm": "Lot ID 15ABR72423 analysis overview:: Strong, sweet and candy characteristics",
		"parent": 32,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [429.5, -333, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-100, -100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16,
						"f": "ProximaNova-Light",
						"t": "LOT ID 15ABR72423\rANALYSIS OVERVIEW::\rSTRONG, SWEET AND\rCANDY CHARACTERISTICS",
						"j": 0,
						"tr": 100,
						"lh": 19.2000007629395,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 3,
					"rn": 0,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.667], "y": [1]},
							"o": {"x": [0.333], "y": [0]},
							"t": 552,
							"s": [0]
						}, {"t": 604, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 32,
		"ty": 4,
		"nm": "Shape Layer 17",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [519, 603.25, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [-200, -200, 100], "ix": 6}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr", "it": [{
				"ind": 0, "ty": "sh", "ix": 1, "ks": {
					"a": 1,
					"k": [{
						"t": 566,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[166.55, -163.9], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 616,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[52.175, -181.4], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 628,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[127.8, -252.025], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 650,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[50.467, -182.692], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 660,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[188.689, -308.914], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}, {
						"t": 674,
						"s": [{
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[170.133, -291.469], [188.5, -307.75], [444.5, -307.75], [444.5, -405.75], [188.75, -405.75], [188.516, -307.781]],
							"c": false
						}],
						"h": 1
					}],
					"ix": 2
				}, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
		}, {
			"ty": "tm",
			"s": {"a": 0, "k": 0, "ix": 1},
			"e": {
				"a": 1,
				"k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 563, "s": [0]}, {
					"t": 611,
					"s": [100]
				}],
				"ix": 2
			},
			"o": {"a": 0, "k": 0, "ix": 3},
			"m": 1,
			"ix": 2,
			"nm": "Trim Paths 1",
			"mn": "ADBE Vector Filter - Trim",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 33,
		"ty": 4,
		"nm": "Square",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 480, "s": [0], "h": 1}, {"t": 504, "s": [100], "h": 1}], "ix": 11},
			"r": {
				"a": 1,
				"k": [{"t": 480, "s": [0], "h": 1}, {"t": 516, "s": [45], "h": 1}, {
					"t": 534,
					"s": [0],
					"h": 1
				}, {"t": 640, "s": [45], "h": 1}, {"t": 667, "s": [0], "h": 1}, {
					"t": 680,
					"s": [45],
					"h": 1
				}, {"t": 690, "s": [0], "h": 1}],
				"ix": 10
			},
			"p": {"a": 0, "k": [697, 808.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 480, "s": [144, 144, 100], "h": 1}, {"t": 640, "s": [100, 100, 100], "h": 1}, {
					"t": 650,
					"s": [200, 200, 100],
					"h": 1
				}, {"t": 667, "s": [100, 100, 100], "h": 1}, {"t": 680, "s": [300, 300, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 34,
		"ty": 4,
		"nm": "Circle",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 480, "s": [0], "h": 1}, {"t": 491, "s": [100], "h": 1}], "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [697, 808.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 480, "s": [164, 164, 100], "h": 1}, {"t": 540, "s": [100, 100, 100], "h": 1}, {
					"t": 552,
					"s": [164, 164, 100],
					"h": 1
				}, {"t": 616, "s": [100, 100, 100], "h": 1}, {"t": 628, "s": [164, 164, 100], "h": 1}, {
					"t": 650,
					"s": [100, 100, 100],
					"h": 1
				}, {"t": 660, "s": [300, 300, 100], "h": 1}, {"t": 674, "s": [200, 200, 100], "h": 1}, {
					"t": 686,
					"s": [200, 200, 100],
					"h": 1
				}],
				"ix": 6
			}
		},
		"ao": 0,
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 35,
		"ty": 2,
		"nm": "Lines 4",
		"parent": 36,
		"refId": "image_7",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.333, "y": 0},
					"t": 488,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1178.594, -2030.5], [101, -2030.5], [101, -274.5], [1178.594, -274.5]],
						"c": true
					}]
				}, {
					"t": 548,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1176.797, -22.5], [101.286, -22.5], [101.286, 1733.5], [1176.797, 1733.5]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ef": [{
			"ty": 20,
			"nm": "Tint",
			"np": 6,
			"mn": "ADBE Tint",
			"ix": 1,
			"en": 1,
			"ef": [{
				"ty": 2,
				"nm": "Map Black To",
				"mn": "ADBE Tint-0001",
				"ix": 1,
				"v": {"a": 0, "k": [0, 0, 0, 0], "ix": 1}
			}, {
				"ty": 2, "nm": "Map White To", "mn": "ADBE Tint-0002", "ix": 2, "v": {
					"a": 1,
					"k": [{
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 48,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 96,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 143,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 191,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 240,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 288,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 335,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 383,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 432,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 480,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 527,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 575,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 624,
						"s": [1, 1, 1, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 672,
						"s": [0.478431373835, 0.764705896378, 0.341176480055, 1]
					}, {
						"i": {"x": [0.667], "y": [1]},
						"o": {"x": [0.333], "y": [0]},
						"t": 719,
						"s": [1, 1, 1, 1]
					}, {"t": 767, "s": [0.478431373835, 0.764705896378, 0.341176480055, 1]}],
					"ix": 2
				}
			}, {
				"ty": 0,
				"nm": "Amount to Tint",
				"mn": "ADBE Tint-0003",
				"ix": 3,
				"v": {"a": 0, "k": 100, "ix": 3}
			}, {"ty": 6, "nm": "", "mn": "ADBE Tint-0004", "ix": 4, "v": 0}]
		}],
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 36,
		"ty": 2,
		"nm": "Hop 3",
		"parent": 2,
		"refId": "image_8",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {
				"a": 1,
				"k": [{"t": 405, "s": [1392, -37.375, 0], "h": 1}, {
					"t": 480,
					"s": [470, -163.375, 0],
					"h": 1
				}, {"t": 528, "s": [-7, -123.375, 0], "h": 1}, {"t": 552, "s": [-7, -26.375, 0], "h": 1}, {
					"t": 650,
					"s": [-7, -86.375, 0],
					"h": 1
				}, {"t": 690, "s": [-7, -222.375, 0], "h": 1}, {"t": 710, "s": [-7, 2117.625, 0], "h": 1}],
				"ix": 2
			},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 405, "s": [50, 50, 100], "h": 1}, {"t": 528, "s": [70, 70, 100], "h": 1}, {
					"t": 552,
					"s": [40, 40, 100],
					"h": 1
				}, {"t": 650, "s": [70, 70, 100], "h": 1}, {"t": 690, "s": [100, 100, 100], "h": 1}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "a",
			"pt": {
				"a": 1,
				"k": [{
					"i": {"x": 0.667, "y": 1},
					"o": {"x": 0.333, "y": 0},
					"t": 492,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[1279, -199.75], [85, -199.75], [85, 1629.5], [1279, 1629.5]],
						"c": true
					}]
				}, {
					"t": 552,
					"s": [{
						"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
						"v": [[189.5, -167.75], [85, -167.75], [85, 1629.5], [189.5, 1629.5]],
						"c": true
					}]
				}],
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 37,
		"ty": 2,
		"nm": "Hop 4",
		"parent": 36,
		"refId": "image_9",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 677.5, 0], "ix": 2},
			"a": {"a": 0, "k": [677, 677.5, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"ip": 0,
		"op": 978,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 38,
		"ty": 4,
		"nm": "Square 5",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 0, "s": [0], "h": 1}, {"t": 24, "s": [33], "h": 1}], "ix": 11},
			"r": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 0,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 36,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 109,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 180,
					"s": [45]
				}, {"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 192, "s": [0]}, {
					"t": 241,
					"s": [45]
				}],
				"ix": 10
			},
			"p": {"a": 0, "k": [677, 807.5, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 0,
					"s": [144, 144, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 61,
					"s": [250, 250, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 97,
					"s": [150, 150, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 152,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 192,
					"s": [300, 300, 100]
				}, {"t": 222, "s": [200, 200, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 39,
		"ty": 4,
		"nm": "Square 4",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 281, "s": [33], "h": 1}, {"t": 509, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 240,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 355,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 408,
					"s": [0]
				}, {"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 445, "s": [0]}, {
					"t": 456,
					"s": [0]
				}],
				"ix": 10
			},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 240,
					"s": [144, 144, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 281,
					"s": [250, 250, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 432,
					"s": [400, 400, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 456,
					"s": [200, 200, 100]
				}, {"t": 480, "s": [0, 0, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 40,
		"ty": 4,
		"nm": "Square 3",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 264, "s": [33], "h": 1}, {"t": 509, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 240,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 276,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 294,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 312,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 392,
					"s": [0]
				}, {"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 445, "s": [45]}, {
					"t": 480,
					"s": [0]
				}],
				"ix": 10
			},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 240,
					"s": [144, 144, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 392,
					"s": [200, 200, 100]
				}, {"t": 432, "s": [300, 300, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 41,
		"ty": 4,
		"nm": "Square 2",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 480, "s": [0], "h": 1}, {"t": 504, "s": [33], "h": 1}], "ix": 11},
			"r": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 480,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 516,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 534,
					"s": [0]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 640,
					"s": [45]
				}, {
					"i": {"x": [0.667], "y": [1]},
					"o": {"x": [0.333], "y": [0]},
					"t": 667,
					"s": [0]
				}, {"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 680, "s": [45]}, {
					"t": 690,
					"s": [0]
				}],
				"ix": 10
			},
			"p": {"a": 0, "k": [697, 808.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-63.406, 28.594, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 480,
					"s": [144, 144, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 640,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 650,
					"s": [200, 200, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 667,
					"s": [100, 100, 100]
				}, {"t": 680, "s": [300, 300, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"hasMask": true,
		"masksProperties": [{
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-218.962, -221.406], [-218.962, 289.705], [94.927, 289.705], [94.927, -221.406]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 1"
		}, {
			"inv": false,
			"mode": "s",
			"pt": {
				"a": 0,
				"k": {
					"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
					"v": [[-313.406, -135.295], [-313.406, 192.483], [192.149, 192.483], [192.149, -135.295]],
					"c": true
				},
				"ix": 1
			},
			"o": {"a": 0, "k": 100, "ix": 3},
			"x": {"a": 0, "k": 0, "ix": 4},
			"nm": "Mask 2"
		}],
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ty": "rc",
				"d": 1,
				"s": {"a": 0, "k": [433.188, 433.188], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 4},
				"nm": "Rectangle Path 1",
				"mn": "ADBE Vector Shape - Rect",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-63.406, 28.594], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Rectangle 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 42,
		"ty": 4,
		"nm": "Circle 20",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 0, "s": [0], "h": 1}, {"t": 11, "s": [33], "h": 1}], "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 807.5, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 0, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 47,
					"s": [200, 200, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 61,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 109,
					"s": [150, 150, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 152,
					"s": [400, 400, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 180,
					"s": [175, 175, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 203,
					"s": [350, 350, 100]
				}, {"t": 241, "s": [100, 100, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 43,
		"ty": 4,
		"nm": "Circle 19",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 288, "s": [33], "h": 1}, {"t": 432, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [0, 0, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 288,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 301,
					"s": [250, 250, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 325,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 336,
					"s": [50, 50, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 355,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 390,
					"s": [500, 500, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 420,
					"s": [100, 100, 100]
				}, {"t": 432, "s": [0, 0, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 44,
		"ty": 4,
		"nm": "Circle 18",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 240, "s": [0], "h": 1}, {"t": 251, "s": [33], "h": 1}, {"t": 528, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 240, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 300,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 355,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 442,
					"s": [100, 100, 100]
				}, {"t": 480, "s": [450, 450, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 45,
		"ty": 4,
		"nm": "Circle 17",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 480, "s": [0], "h": 1}, {"t": 491, "s": [33], "h": 1}, {"t": 710, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [697, 808.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 480, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 540,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 552,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 616,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 628,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 650,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 660,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 674,
					"s": [200, 200, 100]
				}, {
					"i": {"x": [0.833, 0.833, 0.833], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 686,
					"s": [200, 200, 100]
				}, {"t": 706, "s": [467, 467, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 46,
		"ty": 4,
		"nm": "Circle 24",
		"parent": 12,
		"sr": 1,
		"ks": {
			"o": {"a": 1, "k": [{"t": 4, "s": [0], "h": 1}, {"t": 15, "s": [13], "h": 1}], "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [677, 807.5, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 4, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 51,
					"s": [200, 200, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 65,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 113,
					"s": [150, 150, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 156,
					"s": [400, 400, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 184,
					"s": [175, 175, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 207,
					"s": [350, 350, 100]
				}, {"t": 245, "s": [100, 100, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 4,
		"op": 740,
		"st": 4,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 47,
		"ty": 4,
		"nm": "Circle 23",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 244, "s": [0], "h": 1}, {"t": 292, "s": [13], "h": 1}, {"t": 436, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 244, "s": [0, 0, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 292,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 305,
					"s": [250, 250, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 329,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 340,
					"s": [50, 50, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 359,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 394,
					"s": [500, 500, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 424,
					"s": [100, 100, 100]
				}, {"t": 436, "s": [0, 0, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 4,
		"op": 740,
		"st": 4,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 48,
		"ty": 4,
		"nm": "Circle 22",
		"parent": 27,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 244, "s": [0], "h": 1}, {"t": 255, "s": [13], "h": 1}, {"t": 532, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [657, 778.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 244, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 304,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 359,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 446,
					"s": [100, 100, 100]
				}, {"t": 484, "s": [450, 450, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 4,
		"op": 740,
		"st": 4,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 49,
		"ty": 4,
		"nm": "Circle 21",
		"parent": 36,
		"sr": 1,
		"ks": {
			"o": {
				"a": 1,
				"k": [{"t": 484, "s": [0], "h": 1}, {"t": 495, "s": [13], "h": 1}, {"t": 710, "s": [0], "h": 1}],
				"ix": 11
			},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [697, 808.25, 0], "ix": 2},
			"a": {"a": 0, "k": [-15.203, 68.797, 0], "ix": 1},
			"s": {
				"a": 1,
				"k": [{"t": 484, "s": [164, 164, 100], "h": 1}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 544,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 556,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 620,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 632,
					"s": [164, 164, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 654,
					"s": [100, 100, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 664,
					"s": [300, 300, 100]
				}, {
					"i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 678,
					"s": [200, 200, 100]
				}, {
					"i": {"x": [0.833, 0.833, 0.833], "y": [1, 1, 1]},
					"o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
					"t": 690,
					"s": [200, 200, 100]
				}, {"t": 710, "s": [467, 467, 100]}],
				"ix": 6
			}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"d": 1,
				"ty": "el",
				"s": {"a": 0, "k": [641.594, 641.594], "ix": 2},
				"p": {"a": 0, "k": [0, 0], "ix": 3},
				"nm": "Ellipse Path 1",
				"mn": "ADBE Vector Shape - Ellipse",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [0.478431402468, 0.764705942191, 0.341176470588, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [-15.203, 68.797], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Ellipse 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 4,
		"op": 740,
		"st": 4,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 50,
		"ty": 0,
		"nm": "Mobile Terpenes",
		"refId": "comp_0",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 4, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [540, 960, 0], "ix": 2},
			"a": {"a": 0, "k": [540, 960, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"w": 1080,
		"h": 1920,
		"ip": 0,
		"op": 720,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 51,
		"ty": 1,
		"nm": "Dark Gray Solid 1",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [540, 960, 0], "ix": 2},
			"a": {"a": 0, "k": [960, 540, 0], "ix": 1},
			"s": {"a": 0, "k": [63, 187, 100], "ix": 6}
		},
		"ao": 0,
		"sw": 1920,
		"sh": 1080,
		"sc": "#212121",
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}],
	"markers": [],
	"chars": [{
		"ch": "O",
		"size": 60,
		"style": "Light",
		"w": 76.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -19.638], [-19.739, 0], [0, 19.638], [19.638, 0]],
							"o": [[0, 19.638], [19.638, 0], [0, -19.638], [-19.739, 0]],
							"v": [[5.841, -33.536], [38.47, 1.208], [71.1, -33.536], [38.47, -68.28]],
							"c": true
						},
						"ix": 2
					},
					"nm": "O",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -17.02], [16.113, 0], [0, 16.919], [-16.315, 0]],
							"o": [[0, 16.919], [-16.315, 0], [0, -17.02], [16.113, 0]],
							"v": [[65.057, -33.536], [38.47, -4.028], [11.884, -33.536], [38.47, -63.043]],
							"c": true
						},
						"ix": 2
					},
					"nm": "O",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "O",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "S",
		"size": 60,
		"style": "Light",
		"w": 58.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-11.078, 0], [0, 8.56], [10.776, 3.021], [0, 7.956], [-8.963, 0], [-4.834, -5.841], [0, 0], [9.97, 0], [0, -10.977], [-10.574, -2.921], [0, -9.467], [13.797, 0], [4.33, 5.035]],
							"o": [[5.136, 6.244], [18.127, 0], [0, -13.696], [-9.164, -2.518], [0, -7.251], [7.553, 0], [0, 0], [-5.237, -5.942], [-12.186, 0], [0, 12.387], [9.366, 2.518], [0, 5.74], [-9.567, 0], [0, 0]],
							"v": [[4.633, -9.467], [29.205, 1.208], [53.073, -17.725], [30.313, -37.866], [12.79, -50.656], [28.5, -63.043], [47.736, -54.584], [51.562, -58.813], [28.802, -68.28], [6.747, -50.354], [28.601, -32.025], [47.031, -17.422], [29.306, -4.028], [8.359, -13.797]],
							"c": true
						},
						"ix": 2
					},
					"nm": "S",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "S",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "H",
		"size": 60,
		"style": "Light",
		"w": 70.3,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[56.699, 0], [62.54, 0], [62.54, -67.172], [56.699, -67.172], [56.699, -37.161], [14.099, -37.161], [14.099, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -31.924], [56.699, -31.924]],
							"c": true
						},
						"ix": 2
					},
					"nm": "H",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "H",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "C",
		"size": 60,
		"style": "Light",
		"w": 67.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[-18.832, 0], [-5.942, 7.352], [0, 0], [8.258, 0], [0, 17.322], [-15.61, 0], [-4.23, -5.841], [0, 0], [10.876, 0], [0, -20.645]],
							"o": [[10.876, 0], [0, 0], [-4.23, 5.841], [-15.61, 0], [0, -17.523], [8.258, 0], [0, 0], [-5.74, -7.15], [-18.832, 0], [0, 20.645]],
							"v": [[39.679, 1.208], [64.352, -10.776], [59.518, -13.797], [39.679, -4.028], [11.884, -33.536], [39.679, -63.043], [59.518, -53.275], [64.352, -56.396], [39.679, -68.28], [5.841, -33.536]],
							"c": true
						},
						"ix": 2
					},
					"nm": "C",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "C",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "3",
		"size": 40,
		"style": "Light",
		"w": 54.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-10.977, 0], [0, 11.078], [5.841, 0.604], [0, 10.272], [11.682, 0], [4.633, -5.74], [0, 0], [-7.654, 0], [0, -8.359], [8.862, 0], [1.007, 0.101], [0, 0], [-2.719, 0], [0, -9.668], [10.373, 0], [3.726, 5.338]],
							"o": [[3.827, 6.042], [14.301, 0], [0, -11.078], [5.74, -1.007], [0, -11.38], [-9.869, 0], [0, 0], [4.935, -5.539], [8.661, 0], [0, 8.963], [-2.719, 0], [0, 0], [1.007, -0.101], [10.071, 0], [0, 8.862], [-8.56, 0], [0, 0]],
							"v": [[3.223, -9.97], [26.083, 1.208], [48.743, -18.027], [33.334, -34.744], [47.736, -50.455], [26.083, -68.179], [4.129, -58.008], [7.755, -54.382], [25.781, -62.943], [41.895, -49.951], [24.875, -37.161], [18.631, -37.262], [18.631, -31.824], [24.875, -31.924], [42.902, -18.329], [25.983, -4.028], [7.15, -13.394]],
							"c": true
						},
						"ix": 2
					},
					"nm": "3",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "3",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {"ch": " ", "size": 60, "style": "Light", "w": 25.9, "data": {}, "fFamily": "Proxima Nova"}, {
		"ch": "2",
		"size": 40,
		"style": "Light",
		"w": 58.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 14.703], [9.869, 0], [4.733, -6.445], [0, 0], [-8.359, 0], [0, -9.769], [22.861, -18.43]],
							"o": [[0, 0], [0, 0], [0, 0], [19.437, -15.71], [0, -13.394], [-9.467, 0], [0, 0], [3.625, -5.136], [7.352, 0], [0, 12.891], [0, 0]],
							"v": [[5.64, 0], [49.951, 0], [49.951, -5.237], [14.603, -5.237], [49.649, -49.146], [28.5, -68.179], [6.143, -57.806], [10.071, -54.181], [28.5, -62.943], [43.808, -49.146], [5.64, -4.733]],
							"c": true
						},
						"ix": 2
					},
					"nm": "2",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "2",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "L",
		"size": 16,
		"style": "Light",
		"w": 50.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [46.628, 0], [46.628, -5.237], [14.099, -5.237], [14.099, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "L",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "L",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "T",
		"size": 16,
		"style": "Light",
		"w": 56.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[25.479, 0], [31.32, 0], [31.32, -61.935], [53.275, -61.935], [53.275, -67.172], [3.525, -67.172], [3.525, -61.935], [25.479, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "T",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "T",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "I",
		"size": 16,
		"style": "Light",
		"w": 22.3,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "I",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "I",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "D",
		"size": 16,
		"style": "Light",
		"w": 69.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 18.631], [20.847, 0], [0, 0]],
							"o": [[0, 0], [20.847, 0], [0, -18.43], [0, 0], [0, 0]],
							"v": [[8.359, 0], [29.81, 0], [63.748, -33.536], [29.81, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "D",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -15.71], [17.725, 0]],
							"o": [[0, 0], [0, 0], [17.825, 0], [0, 15.71], [0, 0]],
							"v": [[14.099, -5.237], [14.099, -61.935], [29.81, -61.935], [57.706, -33.536], [29.81, -5.237]],
							"c": true
						},
						"ix": 2
					},
					"nm": "D",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "D",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "1",
		"size": 16,
		"style": "Light",
		"w": 30.8,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[16.919, 0], [22.659, 0], [22.659, -67.172], [17.422, -67.172], [2.518, -51.562], [6.143, -47.836], [16.919, -59.317]],
							"c": true
						},
						"ix": 2
					},
					"nm": "1",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "1",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "5",
		"size": 16,
		"style": "Light",
		"w": 58.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-11.179, 0], [0, 13.394], [11.279, 0], [3.323, -3.223], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-6.143, 0], [0, -10.172], [9.668, 0], [4.33, 6.143]],
							"o": [[4.633, 6.747], [12.286, 0], [0, -13.998], [-6.949, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [4.935, -4.532], [9.668, 0], [0, 9.164], [-8.661, 0], [0, 0]],
							"v": [[7.452, -9.769], [30.112, 1.208], [52.771, -20.444], [31.32, -41.895], [15.308, -35.651], [15.308, -61.935], [48.34, -61.935], [48.34, -67.172], [9.467, -67.172], [9.467, -31.924], [13.898, -30.112], [30.212, -36.658], [46.93, -20.242], [30.112, -4.028], [11.279, -13.596]],
							"c": true
						},
						"ix": 2
					},
					"nm": "5",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "5",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "A",
		"size": 16,
		"style": "Light",
		"w": 64.7,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[57.202, 0], [63.647, 0], [36.154, -67.172], [29.004, -67.172], [1.511, 0], [7.956, 0], [14.703, -16.516], [50.455, -16.516]],
							"c": true
						},
						"ix": 2
					},
					"nm": "A",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0]],
							"v": [[32.529, -61.533], [48.642, -21.753], [16.516, -21.753]],
							"c": true
						},
						"ix": 2
					},
					"nm": "A",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "A",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "B", "size": 16, "style": "Light", "w": 61.4, "data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 11.179], [7.15, 1.108], [0, 8.459], [11.179, 0], [0, 0]],
							"o": [[0, 0], [12.085, 0], [0, -7.855], [6.042, -1.208], [0, -10.574], [0, 0], [0, 0]],
							"v": [[8.359, 0], [36.456, 0], [55.792, -18.127], [42.7, -34.644], [54.483, -50.052], [35.953, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -6.848], [8.661, 0]],
							"o": [[0, 0], [0, 0], [8.661, 0], [0, 6.949], [0, 0]],
							"v": [[14.099, -37.061], [14.099, -61.935], [35.248, -61.935], [48.441, -49.448], [35.248, -37.061]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 2,
					"ty": "sh",
					"ix": 3,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -6.848], [8.862, 0]],
							"o": [[0, 0], [0, 0], [9.366, 0], [0, 8.157], [0, 0]],
							"v": [[14.099, -5.237], [14.099, -31.824], [35.651, -31.824], [49.75, -18.53], [35.651, -5.237]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "B",
				"np": 6,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		}, "fFamily": "Proxima Nova"
	}, {
		"ch": "R",
		"size": 16,
		"style": "Light",
		"w": 59.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 12.186], [11.581, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [9.97, -0.403], [0, -12.286], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[47.736, 0], [54.684, 0], [35.248, -28.198], [53.879, -47.534], [33.435, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -27.795], [28.903, -27.795]],
							"c": true
						},
						"ix": 2
					},
					"nm": "R",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -8.459], [8.862, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 8.459], [0, 0], [0, 0], [0, 0], [8.862, 0]],
							"v": [[47.836, -47.534], [32.932, -33.032], [14.099, -33.032], [14.099, -61.935], [32.932, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "R",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "R",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "7",
		"size": 16,
		"style": "Light",
		"w": 50.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[12.286, 0], [18.631, 0], [47.232, -63.144], [47.232, -67.172], [3.323, -67.172], [3.323, -61.935], [40.485, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "7",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "7",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "4",
		"size": 16,
		"style": "Light",
		"w": 54.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[35.349, 0], [41.089, 0], [41.089, -18.329], [50.958, -18.329], [50.958, -23.566], [41.089, -23.566], [41.089, -67.172], [33.334, -67.172], [3.525, -23.465], [3.525, -18.329], [35.349, -18.329]],
							"c": true
						},
						"ix": 2
					},
					"nm": "4",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0]],
							"v": [[35.349, -61.432], [35.349, -23.566], [9.467, -23.566]],
							"c": true
						},
						"ix": 2
					},
					"nm": "4",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "4",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {"ch": "\r", "size": 16, "style": "Light", "w": 0, "fFamily": "Proxima Nova"}, {
		"ch": "N",
		"size": 16,
		"style": "Light",
		"w": 69.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[56.396, 0], [62.036, 0], [62.036, -67.172], [56.296, -67.172], [56.296, -9.97], [14.2, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -58.008]],
							"c": true
						},
						"ix": 2
					},
					"nm": "N",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "N",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "Y",
		"size": 16,
		"style": "Light",
		"w": 61.8,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[28.198, 0], [34.039, 0], [34.039, -28.702], [60.727, -67.172], [53.778, -67.172], [31.119, -34.14], [8.459, -67.172], [1.511, -67.172], [28.198, -28.702]],
							"c": true
						},
						"ix": 2
					},
					"nm": "Y",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "Y",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "V",
		"size": 16,
		"style": "Light",
		"w": 64.7,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[29.004, 0], [36.154, 0], [63.647, -67.172], [57.001, -67.172], [32.529, -6.445], [8.157, -67.172], [1.511, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "V",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "V",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "E",
		"size": 16,
		"style": "Light",
		"w": 56.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [50.958, 0], [50.958, -5.237], [14.099, -5.237], [14.099, -31.824], [50.253, -31.824], [50.253, -37.061], [14.099, -37.061], [14.099, -61.935], [50.958, -61.935], [50.958, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "E",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "E",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "W",
		"size": 16,
		"style": "Light",
		"w": 86.7,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[59.821, 0], [66.266, 0], [85.4, -67.172], [78.955, -67.172], [62.741, -8.057], [46.225, -67.172], [41.089, -67.172], [24.573, -8.057], [8.359, -67.172], [1.913, -67.172], [21.149, 0], [27.594, 0], [43.607, -57.706]],
							"c": true
						},
						"ix": 2
					},
					"nm": "W",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "W",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": ":",
		"size": 16,
		"style": "Light",
		"w": 21.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -2.417], [-2.417, 0], [0, 2.316], [2.417, 0]],
							"o": [[0, 2.316], [2.417, 0], [0, -2.417], [-2.417, 0]],
							"v": [[6.546, -44.916], [10.977, -40.485], [15.509, -44.916], [10.977, -49.347]],
							"c": true
						},
						"ix": 2
					},
					"nm": ":",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -2.316], [-2.417, 0], [0, 2.417], [2.417, 0]],
							"o": [[0, 2.417], [2.417, 0], [0, -2.316], [-2.417, 0]],
							"v": [[6.546, -3.525], [10.977, 1.007], [15.509, -3.525], [10.977, -7.855]],
							"c": true
						},
						"ix": 2
					},
					"nm": ":",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": ":",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "G",
		"size": 16,
		"style": "Light",
		"w": 71.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -20.746], [-18.933, 0], [-6.042, 6.647], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [8.963, 0], [0, 17.322], [-15.811, 0], [-4.733, -5.64], [0, 0], [11.078, 0]],
							"o": [[0, 20.746], [10.574, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-3.726, 3.726], [-15.811, 0], [0, -17.523], [8.56, 0], [0, 0], [-6.143, -7.05], [-18.933, 0]],
							"v": [[5.841, -33.536], [39.88, 1.309], [65.359, -9.869], [65.359, -33.334], [34.14, -33.334], [34.14, -28.098], [59.619, -28.098], [59.619, -12.085], [39.88, -3.928], [11.884, -33.536], [39.88, -63.043], [60.828, -53.677], [65.158, -56.799], [39.88, -68.28]],
							"c": true
						},
						"ix": 2
					},
					"nm": "G",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "G",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": ",",
		"size": 16,
		"style": "Light",
		"w": 21.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 5.64], [2.719, 0], [0, -2.316], [-2.417, 0], [0, 0], [3.223, -2.216]],
							"o": [[3.928, -2.719], [0, -4.129], [-2.417, 0], [0, 2.417], [0, 0], [-0.201, 3.122], [0, 0]],
							"v": [[8.762, 12.286], [16.013, -1.41], [10.876, -7.855], [6.546, -3.525], [10.675, 0.705], [11.682, 0.604], [5.841, 9.869]],
							"c": true
						},
						"ix": 2
					},
					"nm": ",",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": ",",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "M",
		"size": 32,
		"style": "Light",
		"w": 78.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[65.359, 0], [71.201, 0], [71.201, -67.172], [62.54, -67.172], [39.78, -11.481], [16.919, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -59.619], [38.672, 0], [40.887, 0], [65.359, -59.619]],
							"c": true
						},
						"ix": 2
					},
					"nm": "M",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "M",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "9",
		"size": 16,
		"style": "Light",
		"w": 58.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-8.762, 0], [0, 17.221], [18.127, 0], [0, -10.977], [-12.085, 0], [-2.921, 4.633], [0, 0], [14.703, 0], [3.323, 4.33]],
							"o": [[4.028, 5.035], [18.43, 0], [0, -18.027], [-14.401, 0], [0, 14.301], [9.064, 0], [0, 0], [0, 14.099], [-7.352, 0], [0, 0]],
							"v": [[9.164, -7.452], [27.292, 1.108], [52.972, -33.536], [28.299, -68.28], [5.74, -46.829], [27.393, -25.479], [47.131, -36.758], [47.131, -33.636], [27.292, -4.129], [12.488, -11.581]],
							"c": true
						},
						"ix": 2
					},
					"nm": "9",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[-0.806, -9.164], [7.352, 0], [0, 10.977], [-11.481, 0]],
							"o": [[-3.827, 6.747], [-9.567, 0], [0, -7.251], [12.589, 0]],
							"v": [[46.729, -42.197], [28.4, -30.716], [11.581, -46.93], [28.5, -63.043]],
							"c": true
						},
						"ix": 2
					},
					"nm": "9",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "9",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "F",
		"size": 16,
		"style": "Light",
		"w": 54.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -31.824], [50.253, -31.824], [50.253, -37.061], [14.099, -37.061], [14.099, -61.935], [50.958, -61.935], [50.958, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "F",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "F",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "U",
		"size": 16,
		"style": "Light",
		"w": 68.6,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-17.221, 0], [0, 16.617], [0, 0], [0, 0], [0, 0], [13.193, 0], [0, 13.596], [0, 0], [0, 0]],
							"o": [[0, 16.516], [17.322, 0], [0, 0], [0, 0], [0, 0], [0, 13.596], [-13.193, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, -25.781], [34.543, 1.208], [60.727, -25.781], [60.727, -67.172], [54.886, -67.172], [54.886, -25.882], [34.543, -4.028], [14.2, -25.882], [14.2, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "U",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "U",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "8", "size": 16, "style": "Light", "w": 57.1, "data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -9.366], [-12.991, 0], [0, 10.876], [8.56, 2.417], [0, 8.963], [10.474, 0], [0, -11.884], [-7.956, -2.014]],
							"o": [[0, 10.977], [12.891, 0], [0, -9.366], [7.855, -2.014], [0, -11.884], [-10.675, 0], [0, 8.963], [-8.56, 2.417]],
							"v": [[5.942, -16.818], [28.802, 1.208], [51.562, -16.818], [34.946, -34.644], [50.555, -50.858], [28.802, -68.179], [7.05, -50.858], [22.659, -34.644]],
							"c": true
						},
						"ix": 2
					},
					"nm": "8",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -7.956], [4.129, -0.806], [0, 9.366], [-8.862, 0]],
							"o": [[0, 9.366], [-4.028, -0.806], [0, -7.956], [8.762, 0]],
							"v": [[44.714, -50.253], [28.802, -37.061], [12.891, -50.253], [28.802, -62.943]],
							"c": true
						},
						"ix": 2
					},
					"nm": "8",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 2,
					"ty": "sh",
					"ix": 3,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -10.172], [8.762, 0], [0, 8.157], [-4.129, 0.504]],
							"o": [[0, 8.157], [-8.963, 0], [0, -10.172], [4.028, 0.504]],
							"v": [[45.822, -17.221], [28.802, -4.028], [11.783, -17.221], [28.802, -32.025]],
							"c": true
						},
						"ix": 2
					},
					"nm": "8",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "8",
				"np": 6,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		}, "fFamily": "Proxima Nova"
	}, {
		"ch": "0",
		"size": 16,
		"style": "Light",
		"w": 60.8,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -16.013], [-18.127, 0], [0, 15.912], [17.926, 0]],
							"o": [[0, 15.912], [17.926, 0], [0, -16.013], [-18.127, 0]],
							"v": [[5.841, -33.536], [30.716, 1.208], [55.49, -33.536], [30.716, -68.179]],
							"c": true
						},
						"ix": 2
					},
					"nm": "0",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -13.898], [13.998, 0], [0, 13.797], [-14.2, 0]],
							"o": [[0, 13.797], [-14.2, 0], [0, -13.898], [14.099, 0]],
							"v": [[49.347, -33.536], [30.716, -4.028], [11.884, -33.536], [30.716, -62.943]],
							"c": true
						},
						"ix": 2
					},
					"nm": "0",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "0",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "P",
		"size": 16,
		"style": "Light",
		"w": 57.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 10.574], [12.891, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [12.79, 0], [0, -10.574], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -27.896], [33.536, -27.896], [53.778, -47.534], [33.536, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "P",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -8.459], [8.862, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 8.459], [0, 0], [0, 0], [0, 0], [8.862, 0]],
							"v": [[47.736, -47.534], [32.932, -33.133], [14.099, -33.133], [14.099, -61.935], [32.932, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "P",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "P",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}]
};